import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {OpenSalesPopupComponent} from './open-sales-popup.component';
import {EventSession} from '../../shared/types/events';

@Injectable({
    providedIn: 'root'
})
export class OpenSalesPopupService {
    constructor(
        private readonly matDialog: MatDialog
    ) {
    }

    public open(eventSessions: EventSession[]): MatDialogRef<OpenSalesPopupComponent> {
        return this.matDialog.open(OpenSalesPopupComponent, {
            panelClass: 'primary-popup-panel',
            backdropClass: 'primary-popup-backdrop',
            data: eventSessions,
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        });
    }
}
